<template>
  <TTView>
    <VRow>
      <VCol>
        <TeamForm
          :entity="team"
          :loading="loading"
          @update:name="team.name = $event"
          @update:displayName="team.displayName = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TeamForm from '../../components/org/TeamForm.vue';

export default {
  name: 'OrgTeamEdit',

  components: {
    TeamForm,
  },

  data() {
    return {
      loading: false,
      team: {
        id: null,
        name: null,
        displayName: null,
        teamType: null,
        companyId: null,
        externalId: null,
        path: [],
        staffPositionsCounter: null,
        staffPositionsWithDescendantsCounter: null,
        hasChildren: false,
      },
    };
  },

  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
    teamId() {
      return this.$route.params.teamId;
    },
  },
  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const data = {
          id: this.teamId,
          companyId: this.companyId,
        };
        const { team } = await this.$di.api.Orgstructure.teamGet(data);

        this.team = team;
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;

        const data = {
          id: this.teamId,
          companyId: this.companyId,
          team: {
            name: this.team.name,
            displayName: this.team.displayName,
            externalId: this.team.externalId,
          },
        };

        await this.$di.api.Orgstructure.teamEdit(data);

        this.$di.notify.snackSuccess('Команда обновлена');
        this.$router.go(-1);
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
