<template>
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VCardTitle>
      Редактирование команды
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.name"
                label="Название"
                name="name"
                persistent-hint
                hint="название команды"
                @input="$emit('update:name', $event)"
              />
            </VCol>
          </VRow>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.displayName"
                label="Отображаемое название"
                name="displayName"
                persistent-hint
                hint="отображаемое название команды"
                @input="$emit('update:displayName', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        Сохранить
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
export default {
  name: 'TeamForm',

  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};
</script>
